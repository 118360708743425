import React from 'react';
import cn from 'classnames';
// Helpers
import { computeMerchBackgroundColor } from 'helpers/merch';
// Types
import { ColorItemFilter } from 'components/common2/PageWithFiltersWrapper/PageWithFiltersContext';
// Components
import Icon from 'ui/Icon/Icon';

import styles from './ColorPicker.module.scss';

export type ColorPickerOption = {
  value: string;
  colorCode: string;
  colorCode2?: string | null;
  disabled?: boolean;
};

type ColorPickerProps = {
  options: ColorPickerOption[];
  checkedValue?: string;
  checkedValuesOptions?: ColorItemFilter[];
  onChange: (
    value: string,
    colorCode: string,
    colorCode2: string | null
  ) => void;
  colorPickerName?: string;
  className?: string;
  role?: 'radio' | 'checkbox';
  fillFullWidth?: boolean;
};

const ColorPicker: React.FC<ColorPickerProps> = ({
  options,
  checkedValue,
  checkedValuesOptions,
  colorPickerName = 'Color picker',
  onChange,
  className,
  role = 'radio',
  fillFullWidth = true,
}) => {
  return (
    <ul
      className={cn(
        styles.colorPickerWrapper,
        { [styles.fillFullWidth]: fillFullWidth },
        className
      )}
      role="radiogroup"
      aria-label={colorPickerName}
    >
      {options.map(
        ({ value, colorCode, colorCode2, disabled }: ColorPickerOption) => {
          const handleColorChange = () =>
            !disabled ? onChange(value, colorCode, colorCode2 || null) : null;
          return (
            <li key={`${value}_${colorCode}_${colorCode2 || ''}`}>
              <div
                className={cn(styles.colorPickerItem, {
                  [styles.disabled]: disabled,
                })}
                style={computeMerchBackgroundColor(colorCode, colorCode2)}
                onKeyPress={handleColorChange}
                onClick={handleColorChange}
                role={role}
                aria-checked={
                  checkedValuesOptions?.length
                    ? checkedValuesOptions?.some(
                        (i) =>
                          i.color === value &&
                          i.colorCode === colorCode &&
                          i?.colorCode2 === colorCode2
                      )
                    : checkedValue === value
                }
                tabIndex={0}
              >
                <Icon name="deprecated-circle-check" className={styles.icon} />
              </div>
            </li>
          );
        }
      )}
    </ul>
  );
};

export default ColorPicker;
