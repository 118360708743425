import { ChangeEvent } from 'react';
// Context
import { useFiltersContext } from 'components/common2/PageWithFiltersWrapper/PageWithFiltersContext';
// Ui2
import CheckBox from 'ui2/CheckBox/CheckBox';
// Components
import FilterItem from '../FilterItem/FilterItem';
// Styles
import styles from './ProductTypeFilter.module.scss';

type ProductTypeFilterProps = {
  isStreamer?: boolean;
  isShoppingEnabled?: boolean;
};

export const ProductTypeFilter = ({
  isStreamer,
  isShoppingEnabled,
}: ProductTypeFilterProps) => {
  const { filters, setFilters } = useFiltersContext();

  const onIsShoppingEnabledCheckBoxChange = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      ...{ isShoppingEnabled: checked ? ['true'] : [] },
    });
  };

  const onIsStreamerCheckBoxChange = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      ...{ isStreamer: checked ? ['true'] : [] },
    });
  };

  return (
    <FilterItem title="Product Type">
      <CheckBox
        className={styles.filterListItemCheckbox}
        name="isShoppingEnabled"
        label="Has Products Available"
        labelClassName={styles.filterListItemCheckboxLabel}
        onChange={onIsShoppingEnabledCheckBoxChange}
        checked={isShoppingEnabled}
      />

      <CheckBox
        className={styles.filterListItemCheckbox}
        name="isStreamer"
        label="Streamers & Content Creators"
        labelClassName={styles.filterListItemCheckboxLabel}
        onChange={onIsStreamerCheckBoxChange}
        checked={isStreamer}
      />
    </FilterItem>
  );
};

export default ProductTypeFilter;
