import { useState, ReactNode } from 'react';
import cn from 'classnames';
import dynamic from 'next/dynamic';
// Hooks
import { useBreakpoints, useHasMounted } from 'hooks';
// Types
import { SortKey } from 'components/common2/Sorting/Sorting';
import { GetAllAmas_amas_filters } from 'api/ama/types/GetAllAmas';
import { GetStores_stores_filters } from 'api/store/types/GetStores';
import { GetStreams_streamsV2_filtersV2 } from 'api/streams/types/GetStreams';
import { SearchLocation } from 'api/graphql-analytics-global-types';
// Context
import { useFiltersContext } from 'components/common2/PageWithFiltersWrapper/PageWithFiltersContext';
// Layout
import ResolutionWrapper from 'layouts/ResolutionWrapper/ResolutionWrapper';
// Ui2
import Loader from 'ui2/Loader/Loader';
import BackgroundTitle from 'ui2/BackgroundTitle/BackgroundTitle';
// Layout
import Main from 'layouts/Main/Main';
// Components
import Filters from 'components/common2/PageWithFiltersWrapper/components/Filters/Filters';
// Dynamic imports
const Sorting = dynamic(() => import('components/common2/Sorting/Sorting'), {
  ssr: true,
});
const FiltersToggle = dynamic(
  () =>
    import(
      'components/common2/PageWithFiltersWrapper/components/FiltersToggle/FiltersToggle'
    ),
  { ssr: true }
);
const SearchBar = dynamic(
  () =>
    import(
      'components/common2/PageWithFiltersWrapper/components/SearchBar/SearchBar'
    ),
  { ssr: true }
);
const SearchBarToggle = dynamic(
  () =>
    import(
      'components/common2/PageWithFiltersWrapper/components/SearchBarToggle/SearchBarToggle'
    ),
  { ssr: true }
);
const NewsLetter = dynamic(
  () => import('components/common2/NewsLetter/NewsLetter'),
  { ssr: false }
);
// Styles
import styles from './PageWithFiltersWrapper.module.scss';

export type ExtraStoreFilters = {
  isStreamer?: boolean;
  isShoppingEnabled?: boolean;
};

type AllFilters =
  | Omit<GetAllAmas_amas_filters, '__typename'>
  | Omit<GetStores_stores_filters, '__typename'>
  | Omit<GetStreams_streamsV2_filtersV2, '__typename'>
  | Omit<ExtraStoreFilters, '__typename'>;

type PageWithFiltersWrapperProps = {
  sortKey: SortKey;
  title?: string;
  loading?: boolean;
  children?: ReactNode;
  filters?: Partial<AllFilters> | null | undefined;
  isPage?: boolean;
  noClear?: boolean;
  searchBarLocation: SearchLocation;
};

const PageWithFiltersWrapper = ({
  sortKey,
  title,
  loading,
  children,
  filters,
  isPage = true,
  noClear = false,
  searchBarLocation,
}: PageWithFiltersWrapperProps) => {
  const [filtersModalIsVisible, setFiltersModalIsVisible] = useState<boolean>(
    false
  );
  const [
    searchBarModalIsVisible,
    setSearchBarModalIsVisible,
  ] = useState<boolean>(false);

  const { sorting, setSorting } = useFiltersContext();
  const { isBreakpointL } = useBreakpoints();
  const hasMounted = useHasMounted();

  const isDesktopComponentsVisible = hasMounted ? isBreakpointL : true;
  const isMobileComponentsVisible = hasMounted ? !isBreakpointL : true;

  const toggleFiltersVisibility = () => {
    setFiltersModalIsVisible(!filtersModalIsVisible);
  };

  const toggleSearchBarVisibility = () => {
    setSearchBarModalIsVisible(!searchBarModalIsVisible);
  };

  const closeSearchBarModal = () => {
    setSearchBarModalIsVisible(false);
  };

  return (
    <div className={cn(styles.root, { [styles.withNewsLetter]: isPage })}>
      {title && (
        <>
          <div
            className={cn(styles.backgroundTitleWrapper, {
              [styles.centeredBackgroundTitleWrapper]: !isPage,
            })}
          >
            <BackgroundTitle content={title} />
          </div>
        </>
      )}

      <Main
        backgroundColor="transparent"
        withoutSidesPadding
        className={cn({ [styles.adjustedMain]: !isPage })}
      >
        <ResolutionWrapper>
          {title && (
            <div
              className={cn(styles.titleWrapper, {
                [styles.centeredAthleteSorting]: !isPage,
              })}
            >
              <h1 className={styles.title}>{title}</h1>
              {!isPage && (
                <Sorting
                  wrapperClassName={styles.athleteSorting}
                  sortKey={sortKey}
                  sorting={sorting}
                  setSorting={setSorting}
                />
              )}
            </div>
          )}

          <div
            className={cn(styles.pageWrapper, {
              [styles.withNewsLetter]: isPage,
            })}
          >
            <aside className={styles.asideWrapper}>
              {isDesktopComponentsVisible && (
                <Filters
                  filters={filters}
                  loading={loading}
                  noClear={noClear}
                />
              )}
            </aside>

            <div className={styles.contentWrapper}>
              {!noClear && (
                <div className={cn(styles.contentHeader, styles.desktop)}>
                  {isDesktopComponentsVisible && (
                    <>
                      <SearchBar
                        sortKey={sortKey}
                        wrapperClassName={styles.sectionSearchBar}
                        location={searchBarLocation}
                      />

                      <Sorting
                        wrapperClassName={styles.sectionSorting}
                        sortKey={sortKey}
                        sorting={sorting}
                        setSorting={setSorting}
                      />
                    </>
                  )}
                </div>
              )}

              <div className={cn(styles.contentHeader, styles.mobile)}>
                {isMobileComponentsVisible && (
                  <>
                    {isPage && (
                      <SearchBarToggle
                        searchBarModalIsVisible={searchBarModalIsVisible}
                        toggleSearchBarVisibility={toggleSearchBarVisibility}
                      >
                        <SearchBar
                          inModal
                          sortKey={sortKey}
                          onSearch={closeSearchBarModal}
                          location={searchBarLocation}
                        />
                      </SearchBarToggle>
                    )}

                    <FiltersToggle
                      filtersModalIsVisible={filtersModalIsVisible}
                      toggleFiltersVisibility={toggleFiltersVisibility}
                    >
                      <Filters
                        filters={filters}
                        setFiltersModalIsVisible={setFiltersModalIsVisible}
                        sortKey={sortKey}
                        loading={loading}
                        noClear={noClear}
                        inModal
                      />
                    </FiltersToggle>
                  </>
                )}
              </div>

              <div className={styles.contentBody}>
                {loading && <Loader loading size="xLarge" />}
                {children}
              </div>
            </div>
          </div>
        </ResolutionWrapper>
        {isPage && <NewsLetter />}
      </Main>
    </div>
  );
};

export default PageWithFiltersWrapper;
