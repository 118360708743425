import { ChangeEvent, useMemo } from 'react';
import { useQuery } from '@apollo/client';
// Context
import { useFiltersContext } from 'components/common2/PageWithFiltersWrapper/PageWithFiltersContext';
// Api
import { GET_STORES_NAMES_LIST } from 'api/store/queries';
// Types
import {
  UserRole,
  StoresOrderBy,
  SortDirection,
} from 'api/graphql-global-types';
import {
  GetStoresNamesList,
  GetStoresNamesListVariables,
  GetStoresNamesList_stores_entities,
} from 'api/store/types/GetStoresNamesList';
// Ui
import InfiniteScrollLoader from 'ui/InfiniteScrollLoader/InfiniteScrollLoader';
// Ui2
import CheckBox from 'ui2/CheckBox/CheckBox';
// Components
import FilterItem from '../FilterItem/FilterItem';
import InfiniteScroll from 'components/common/InfiniteScroll/InfiniteScroll';
// Styles
import styles from './AthletesFilter.module.scss';

const PER_PAGE = 9;

const AthletesFilter = () => {
  const {
    filters,
    storesListFilter,
    setFilters,
    searchKey,
    search,
  } = useFiltersContext();

  const inputVariables = useMemo(
    () => ({
      orderBy: StoresOrderBy.storeName,
      direction: SortDirection.ASC,
      limit: PER_PAGE,
      offset: 0,
      ...(storesListFilter && {
        [storesListFilter]: true,
      }),
    }),
    [storesListFilter]
  );

  const { data, fetchMore } = useQuery<
    GetStoresNamesList,
    GetStoresNamesListVariables
  >(GET_STORES_NAMES_LIST, {
    variables: {
      storeRoles: [UserRole.Athlete],
      input: {
        ...inputVariables,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const onCheckBoxChange = ({
    target: { id, checked },
  }: ChangeEvent<HTMLInputElement>) => {
    const athleteIdFromSlug = athletes.find(
      (athlete) => athlete.slug === filters?.athlete?.[0]
    )?.id;
    const athleteIds: string[] =
      filters?.athleteIds?.includes(id) || id === athleteIdFromSlug
        ? filters?.athleteIds?.filter((athleteId) => athleteId !== id) || []
        : [...(filters?.athleteIds || []), id];

    if (checked && athleteIdFromSlug) {
      athleteIds.push(athleteIdFromSlug);
    }

    setFilters({ ...filters, athleteIds, athlete: [] });
  };

  const fetchMoreData = () => {
    const prevOffset = data?.stores?.offset || 0;
    const offset = prevOffset ? prevOffset + PER_PAGE : PER_PAGE;

    fetchMore({
      variables: {
        input: {
          ...inputVariables,
          offset,
        },
      },
    });
  };

  const athletes = data?.stores.entities || [];
  const total = data?.stores.total;
  const hasMore = data ? athletes.length !== total : false;
  const infiniteScrollHeight = !athletes?.length
    ? 48
    : athletes.length >= 6
    ? 240
    : athletes.length * 48;

  const getIfChecked = (
    athlete: GetStoresNamesList_stores_entities
  ): boolean => {
    let isChecked =
      Boolean(filters?.athleteIds?.includes(athlete?.id)) ||
      filters?.athlete?.[0] === athlete.slug;

    if (
      searchKey === 'storeName' &&
      athlete?.storeDetails?.storeName === search?.[searchKey]
    ) {
      isChecked = true;
    }

    return isChecked;
  };

  const getLabel = (athlete: GetStoresNamesList_stores_entities) => {
    return athlete?.storeDetails?.storeName &&
      athlete?.storeDetails?.storeName.trim() !== ''
      ? athlete?.storeDetails?.storeName
      : `${athlete?.firstName} ${athlete?.lastName}`;
  };

  return (
    <FilterItem title="Athletes">
      <InfiniteScroll
        className={styles.athletesFilterWrapper}
        dataLength={athletes.length}
        next={fetchMoreData}
        hasMore={hasMore}
        height={infiniteScrollHeight}
        loader={<InfiniteScrollLoader size="small" />}
      >
        <ul>
          {athletes.map((athlete) => (
            <li key={athlete.id}>
              <CheckBox
                className={styles.filterListItemCheckbox}
                name={athlete.id}
                label={getLabel(athlete)}
                labelClassName={styles.filterListItemCheckboxLabel}
                onChange={onCheckBoxChange}
                checked={getIfChecked(athlete)}
              />
            </li>
          ))}
        </ul>
      </InfiniteScroll>
    </FilterItem>
  );
};

export default AthletesFilter;
