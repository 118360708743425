import React, { forwardRef } from 'react';
import cn from 'classnames';

import styles from './Input.module.scss';

export type InputProps = {
  name: string;
} & React.ComponentPropsWithRef<'input'>;
type Ref = HTMLInputElement;

const Input = forwardRef<Ref, InputProps>(
  (
    { name, className, id = name, prefix, value, onChange, ...inputProps },
    ref
  ) => {
    // 4ch ≈ 32px
    const width = `${value}`.length < 4 ? 4 : `${value}`.length;
    const inputId = `${id}-text-field`;

    return (
      <div className={cn(className)}>
        <div className={cn(styles.inputWrapper)}>
          <input
            ref={ref}
            id={inputId}
            name={name}
            type={'number'}
            className={cn(styles.input, {
              [styles.withPrefix]: prefix,
            })}
            value={value}
            {...inputProps}
            onChange={onChange}
            autoComplete="off"
            style={{ width: `calc(${width}ch + 4px)` }}
          />
        </div>
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
