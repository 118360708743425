import { ChangeEvent } from 'react';
// Hooks
import { useGetSports } from 'hooks';
// Types
import { GetStores_stores_filters_hashtags } from 'api/store/types/GetStores';
// Context
import { useFiltersContext } from 'components/common2/PageWithFiltersWrapper/PageWithFiltersContext';
// Ui2
import CheckBox from 'ui2/CheckBox/CheckBox';
// Components
import FilterItem from '../FilterItem/FilterItem';
// Styles
import styles from './TagsFilter.module.scss';

type TagsFilterProps = {
  availableOptions: GetStores_stores_filters_hashtags[];
};

export const TagsFilter = ({ availableOptions }: TagsFilterProps) => {
  const { filters, setFilters } = useFiltersContext();
  const { data } = useGetSports();

  const allSports = data?.sports || [];

  const selectedSportsWithHashtags = allSports.filter((item) =>
    filters?.sports?.includes(item.id)
  );

  // show options that are related to selected sports only
  const tagsOptions = availableOptions.filter((option) =>
    selectedSportsWithHashtags.some((sportItem) =>
      sportItem.hashtags?.find((tag) => tag.id === option.id)
    )
  );

  const onCheckBoxChange = ({
    target: { id },
  }: ChangeEvent<HTMLInputElement>) => {
    const hashtagIds: number[] = filters?.hashtagIds?.includes(Number(id))
      ? filters?.hashtagIds?.filter((i) => i !== Number(id)) || []
      : [...(filters?.hashtagIds || []), Number(id)];

    setFilters({ ...filters, hashtagIds });
  };

  const sortedTagsOptions = tagsOptions.slice().sort((a, b) => {
    const nameA = a?.name?.toLowerCase() || '';
    const nameB = b?.name?.toLowerCase() || '';

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  // hide filter if no selected sport or options are empty
  if (!filters?.sports?.length || !sortedTagsOptions.length) {
    return null;
  }

  return (
    <FilterItem title="Tags">
      <ul>
        {sortedTagsOptions?.map((option, index) => (
          <li key={`${index}_${option.id}`}>
            <CheckBox
              className={styles.filterListItemCheckbox}
              name={String(option.id)}
              label={option.name || 'Empty tag name'}
              labelClassName={styles.filterListItemCheckboxLabel}
              onChange={onCheckBoxChange}
              checked={Boolean(
                option?.id && filters?.hashtagIds?.includes(option.id)
              )}
            />
          </li>
        ))}
      </ul>
    </FilterItem>
  );
};

export default TagsFilter;
