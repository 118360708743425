import React from 'react';
import ReactInfiniteScroll, {
  Props as ReactInfiniteScrollProps,
} from 'react-infinite-scroll-component';
import cn from 'classnames';

import styles from './InfiniteScroll.module.scss';

type InfiniteScrollProps = {
  isOverflowVisible?: boolean;
} & ReactInfiniteScrollProps;

export const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  children,
  isOverflowVisible,
  className,
  ...props
}) => {
  return (
    <ReactInfiniteScroll
      className={cn({ [styles.scroll]: isOverflowVisible }, className)}
      {...props}
    >
      {children}
    </ReactInfiniteScroll>
  );
};

export default InfiniteScroll;
