import { ReactNode } from 'react';
import cn from 'classnames';
// Components
import Collapse from 'ui/Collapse/Collapse';
// Styles
import styles from './FilterItem.module.scss';

type FilterItemProps = {
  title: string;
  filterBodyClassName?: string;
  className?: string;
  children?: ReactNode;
};

const FilterItem = ({
  title,
  filterBodyClassName,
  className,
  children,
}: FilterItemProps) => (
  <Collapse
    open
    className={cn(styles.filterItem, className)}
    bodyClassName={filterBodyClassName}
    title={<h4 className={styles.filterItemHeaderTitle}>{title}</h4>}
  >
    {children}
  </Collapse>
);

export default FilterItem;
