import React from 'react';
import cn from 'classnames';

import styles from './InfiniteScrollLoader.module.scss';

type InfiniteScrollLoaderProps = {
  size?: 'small' | 'medium';
};

const InfiniteScrollLoader: React.FC<InfiniteScrollLoaderProps> = ({
  size = 'medium',
}) => {
  return (
    <div
      className={cn(styles.infiniteScrollLoaderWrapper, {
        [styles[`size-${size}`]]: size,
      })}
    >
      <div
        className={cn(styles.infiniteScrollLoaderDots, {
          [styles[`size-${size}`]]: size,
        })}
      />
    </div>
  );
};

export default InfiniteScrollLoader;
