import React from 'react';
import RcSlider from 'rc-slider';
// Types
import { SliderProps } from 'rc-slider';

import styles from './Slider.module.scss';

const Slider: React.FC<SliderProps> = ({ ...rest }) => {
  const trackStyle = {
    height: '16px',
    backgroundColor: '#000000',
    border: '0.5px solid #939393',
    borderRadius: '10px',
    transform: 'translate(0)',
  };

  return (
    <div className={styles.sliderWrapper}>
      <RcSlider
        className={styles.sliderContainer}
        handleStyle={{
          width: '18px',
          height: '18px',
          backgroundColor: '#000000',
          border: '2px solid #ffffff',
          borderRadius: '100%',
        }}
        trackStyle={trackStyle}
        railStyle={{
          ...trackStyle,
          backgroundColor: '#ffffff',
        }}
        dotStyle={{
          display: 'none',
        }}
        activeDotStyle={{
          display: 'none',
        }}
        {...rest}
      />
    </div>
  );
};

export default Slider;
