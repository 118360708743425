import { ChangeEvent } from 'react';
// Types
import { GenderInputFilter } from 'api/graphql-global-types';
import { GetMerchProducts_getMerchProducts_filters_genderV2 } from 'api/merch/types/GetMerchProducts';
// Context
import { useFiltersContext } from 'components/common2/PageWithFiltersWrapper/PageWithFiltersContext';
// Ui2
import CheckBox from 'ui2/CheckBox/CheckBox';
// Components
import FilterItem from '../FilterItem/FilterItem';
// Styles
import styles from './GenderFilter.module.scss';

type GenderFilterProps = {
  availableOptions: GetMerchProducts_getMerchProducts_filters_genderV2[];
};

export const GenderFilter = ({ availableOptions }: GenderFilterProps) => {
  const { filters, setFilters } = useFiltersContext();

  const onCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name: GenderInputFilter = e?.target?.name as GenderInputFilter;

    const genderV2: GenderInputFilter[] = filters?.genderV2?.includes(name)
      ? filters?.genderV2?.filter((i) => i !== name) || []
      : [...(filters?.genderV2 || []), name];

    setFilters({ ...filters, genderV2 });
  };

  return (
    <FilterItem title="Gender">
      <ul>
        {availableOptions?.map((option, index) => (
          <li key={`${index}_${option.name}`}>
            <CheckBox
              className={styles.filterListItemCheckbox}
              name={option.name || ''}
              label={option.name || ''}
              labelClassName={styles.filterListItemCheckboxLabel}
              onChange={onCheckBoxChange}
              checked={Boolean(
                option?.name && filters?.genderV2?.includes(option.name)
              )}
            />
          </li>
        ))}
      </ul>
    </FilterItem>
  );
};

export default GenderFilter;
