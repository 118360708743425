import React from 'react';
import cn from 'classnames';
import ReactDatePicker from 'react-datepicker';
// Types
import { ReactDatePickerProps } from 'react-datepicker';
// Styles
import 'react-datepicker/dist/react-datepicker.css';
import styles from './DatePicker.module.scss';

type DatePickerProps = ReactDatePickerProps & {
  isFilter?: boolean;
};

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    isFilter = false,
    calendarClassName,
    wrapperClassName,
    popperClassName,
    timeFormat = 'hh:mm aa',
    timeIntervals = 15,
    placeholderText = 'Event date and time',
    timeCaption = 'Time',
    dateFormat = 'MM/dd/yyyy h:mm aa',
    ...restProps
  } = props;

  return (
    <ReactDatePicker
      calendarClassName={cn(styles.datePickerCalendar, calendarClassName, {
        [styles.isFilter]: isFilter,
      })}
      wrapperClassName={cn(styles.datePickerWrapper, wrapperClassName)}
      popperClassName={cn(styles.datePickerPopper, popperClassName)}
      timeFormat={timeFormat}
      timeIntervals={timeIntervals}
      placeholderText={placeholderText}
      timeCaption={timeCaption}
      dateFormat={dateFormat}
      {...restProps}
    />
  );
};

export default DatePicker;
