import React from 'react';

import styles from './NotFound.module.scss';

type NotFoundProps = {
  title: string;
};

const NotFound: React.FC<NotFoundProps> = ({ title }) => {
  return <h2 className={styles.title}>{title}</h2>;
};

export default NotFound;
