import { createRef, useState } from 'react';
import cn from 'classnames';
// Hooks
import { useOutsideClick } from 'hooks/useOutsideClick';
// Types
import {
  AmaOrderBy,
  StreamOrderBy,
  StoresOrderBy,
  SortDirection,
  MerchProductOrderBy,
  MemorabiliaOrderBy,
  ExperienceOrderBy,
  MediaPostsOrderBy,
} from 'api/graphql-global-types';
// Components
import SortingList from './components/SortingList/SortingList';
import FilterItem from 'components/common2/PageWithFiltersWrapper/components/Filters/components/FilterItem/FilterItem';
// UI
import Icon from 'ui/Icon/Icon';

import styles from './Sorting.module.scss';

export type SortKey =
  | 'Amas'
  | 'Merch'
  | 'Streams'
  | 'Stores'
  | 'Organizations'
  | 'Athletes'
  | 'Products'
  | 'Experiences'
  | 'MediaPosts';

export type SortingOptions = {
  direction: SortDirection;
  order:
    | AmaOrderBy
    | MerchProductOrderBy
    | StreamOrderBy
    | StoresOrderBy
    | MemorabiliaOrderBy
    | ExperienceOrderBy
    | MediaPostsOrderBy;
};

export type SortItem = SortingOptions & { label: string };

const AmaPriceSortItems: SortItem[] = [
  {
    direction: SortDirection.ASC,
    order: AmaOrderBy.Price,
    label: 'Price: Lowest first',
  },
  {
    direction: SortDirection.DESC,
    order: AmaOrderBy.Price,
    label: 'Price: Highest first',
  },
];

const AmaStoreNameSortItems: SortItem[] = [
  {
    direction: SortDirection.ASC,
    order: AmaOrderBy.StoreName,
    label: 'Name: (A-Z)',
  },
  {
    direction: SortDirection.DESC,
    order: AmaOrderBy.StoreName,
    label: 'Name: (Z-A)',
  },
];

const AmaNewestSortItem: SortItem[] = [
  {
    direction: SortDirection.DESC,
    order: AmaOrderBy.CreatedAt,
    label: 'Date: Latest to Oldest',
  },
];

const ProductPriceSortItems: SortItem[] = [
  {
    direction: SortDirection.ASC,
    order: MemorabiliaOrderBy.price,
    label: 'Price: Lowest first',
  },
  {
    direction: SortDirection.DESC,
    order: MemorabiliaOrderBy.price,
    label: 'Price: Highest first',
  },
];

const ProductNameSortItems: SortItem[] = [
  {
    direction: SortDirection.ASC,
    order: MemorabiliaOrderBy.title,
    label: 'Title: (A-Z)',
  },
  {
    direction: SortDirection.DESC,
    order: MemorabiliaOrderBy.title,
    label: 'Title: (Z-A)',
  },
];

const ProductNewestSortItem: SortItem[] = [
  {
    direction: SortDirection.DESC,
    order: MemorabiliaOrderBy.createdAt,
    label: 'Date: Latest first',
  },
];

const ProductOldestSortItem: SortItem[] = [
  {
    direction: SortDirection.ASC,
    order: MemorabiliaOrderBy.createdAt,
    label: 'Date: Oldest first',
  },
];

const MerchPriceSortItems: SortItem[] = [
  {
    direction: SortDirection.ASC,
    order: MerchProductOrderBy.Price,
    label: 'Price: Lowest first',
  },
  {
    direction: SortDirection.DESC,
    order: MerchProductOrderBy.Price,
    label: 'Price: Highest first',
  },
];

const MerchStoreNameSortItems: SortItem[] = [
  {
    direction: SortDirection.ASC,
    order: MerchProductOrderBy.StoreName,
    label: 'Name: (A-Z)',
  },
  {
    direction: SortDirection.DESC,
    order: MerchProductOrderBy.StoreName,
    label: 'Name: (Z-A)',
  },
];

const MerchTitleSortItems: SortItem[] = [
  {
    direction: SortDirection.ASC,
    order: MerchProductOrderBy.Title,
    label: 'Merch Title: (A-Z)',
  },
  {
    direction: SortDirection.DESC,
    order: MerchProductOrderBy.Title,
    label: 'Merch Title: (Z-A)',
  },
];

const MerchNewestSortItems: SortItem[] = [
  {
    direction: SortDirection.DESC,
    order: MerchProductOrderBy.Date,
    label: 'Date: Latest to Oldest',
  },
];

const StreamsPriceSortItems: SortItem[] = [
  {
    direction: SortDirection.DESC,
    order: StreamOrderBy.Price,
    label: 'Price: Highest first',
  },
  {
    direction: SortDirection.ASC,
    order: StreamOrderBy.Price,
    label: 'Price: Lowest first',
  },
];

const StreamDateSortDirection: SortItem[] = [
  {
    direction: SortDirection.DESC,
    order: StreamOrderBy.ScheduleDate,
    label: 'Date: Oldest first',
  },
  {
    direction: SortDirection.ASC,
    order: StreamOrderBy.ScheduleDate,
    label: 'Date: Latest first',
  },
];

const StreamsNameSortItems: SortItem[] = [
  {
    direction: SortDirection.ASC,
    order: StreamOrderBy.Name,
    label: 'Name: (A-Z)',
  },
  {
    direction: SortDirection.DESC,
    order: StreamOrderBy.Name,
    label: 'Name: (Z-A)',
  },
];

const StoreNameSortItems: SortItem[] = [
  {
    direction: SortDirection.ASC,
    order: StoresOrderBy.storeName,
    label: 'Name: (A-Z)',
  },
  {
    direction: SortDirection.DESC,
    order: StoresOrderBy.storeName,
    label: 'Name: (Z-A)',
  },
  {
    direction: SortDirection.DESC,
    order: StoresOrderBy.totalSales,
    label: 'Most popular',
  },
];

const ExperiencePriceSortItems: SortItem[] = [
  {
    direction: SortDirection.ASC,
    order: ExperienceOrderBy.price,
    label: 'Price: Lowest first',
  },
  {
    direction: SortDirection.DESC,
    order: ExperienceOrderBy.price,
    label: 'Price: Highest first',
  },
];

const ExperienceTitleSortItems: SortItem[] = [
  {
    direction: SortDirection.ASC,
    order: ExperienceOrderBy.title,
    label: 'Title: (A-Z)',
  },
  {
    direction: SortDirection.DESC,
    order: ExperienceOrderBy.title,
    label: 'Title: (Z-A)',
  },
];

const ExperienceNewestSortItems: SortItem[] = [
  {
    direction: SortDirection.DESC,
    order: ExperienceOrderBy.createdAt,
    label: 'Date: Latest to Oldest',
  },
];

type SortingProps = {
  wrapperClassName?: string;
  sortKey: SortKey;
  sorting: SortingOptions;
  setSorting: (options: SortingOptions) => void;
  inModal?: boolean;
};

const Sorting = ({
  wrapperClassName,
  sortKey,
  sorting,
  setSorting,
  inModal,
}: SortingProps) => {
  const sortItems: SortItem[] = [
    ...(sortKey === 'Athletes' ? [...StoreNameSortItems] : []),
    ...(sortKey === 'Organizations' ? [...StoreNameSortItems] : []),
    ...(sortKey === 'Amas'
      ? [...AmaPriceSortItems, ...AmaStoreNameSortItems, ...AmaNewestSortItem]
      : []),
    ...(sortKey === 'Merch'
      ? [
          ...MerchPriceSortItems,
          ...MerchStoreNameSortItems,
          ...MerchTitleSortItems,
          ...MerchNewestSortItems,
        ]
      : []),
    ...(sortKey === 'Streams'
      ? [
          ...StreamsPriceSortItems,
          ...StreamsNameSortItems,
          ...StreamDateSortDirection,
        ]
      : []),
    ...(sortKey === 'Products'
      ? [
          ...ProductPriceSortItems,
          ...ProductNameSortItems,
          ...ProductNewestSortItem,
          ...ProductOldestSortItem,
        ]
      : []),
    ...(sortKey === 'Experiences'
      ? [
          ...ExperiencePriceSortItems,
          ...ExperienceTitleSortItems,
          ...ExperienceNewestSortItems,
        ]
      : []),
  ];

  const initSortOptionLabel =
    sortItems.find(
      (item) =>
        item.direction === sorting?.direction && item.order === sorting?.order
    )?.label || '';

  const [sortListIsVisible, setSortListIsVisible] = useState<boolean>(false);
  const [sortOptionLabel, setSortOptionLabel] = useState<string>(
    initSortOptionLabel
  );

  const toggleSortList = () => {
    setSortListIsVisible(!sortListIsVisible);
  };

  const onSortingListHide = () => {
    setSortListIsVisible(false);
  };

  const onSort = ({ direction, order, label }: SortItem) => {
    setSorting({ direction, order });
    setSortOptionLabel(label);
  };

  const sortingListRef = createRef<HTMLDivElement>();

  useOutsideClick(sortingListRef, onSortingListHide);

  if (inModal) {
    return (
      <FilterItem title="Sort by">
        <SortingList
          isVisible
          sortItems={sortItems}
          sorting={sorting}
          onSort={onSort}
        />
      </FilterItem>
    );
  }

  return (
    <div className={cn(styles.root, wrapperClassName)} ref={sortingListRef}>
      <button
        className={styles.toggleButton}
        onClick={toggleSortList}
        type="button"
      >
        <span className={styles.toggleTextWrapper}>
          <span className={styles.toggleTitle}>Sort by:</span>
          <span className={styles.toggleDescription}>{sortOptionLabel}</span>
        </span>

        <Icon
          name="chevron-down-clean"
          className={cn(styles.toggleArrowIcon, {
            [styles.rotate]: sortListIsVisible,
          })}
        />
      </button>

      <SortingList
        isVisible={sortListIsVisible}
        sortItems={sortItems}
        sorting={sorting}
        onSort={onSort}
        onHide={onSortingListHide}
      />
    </div>
  );
};

export default Sorting;
