// Types
import { ChangeEvent } from 'react';
import { GetMerchProducts_getMerchProducts_filters_size } from 'api/merch/types/GetMerchProducts';
// Context
import { useFiltersContext } from 'components/common2/PageWithFiltersWrapper/PageWithFiltersContext';
// Ui2
import CheckBox from 'ui2/CheckBox/CheckBox';
// Components
import FilterItem from '../FilterItem/FilterItem';
// Styles
import styles from './SizeFilter.module.scss';

type SizeFilterProps = {
  availableOptions: GetMerchProducts_getMerchProducts_filters_size[];
};

export const SizeFilter = ({ availableOptions }: SizeFilterProps) => {
  const { filters, setFilters } = useFiltersContext();

  const onCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name: string = e?.target?.name as string;

    const size: string[] = filters?.size?.includes(name)
      ? filters?.size?.filter((i) => i !== name) || []
      : [...(filters?.size || []), name];

    setFilters({ ...filters, size });
  };

  return (
    <FilterItem title="Size">
      <ul>
        {availableOptions?.map((option, index) => (
          <li key={`${index}_${option.name}`}>
            <CheckBox
              className={styles.filterListItemCheckbox}
              name={option.name || ''}
              label={option.name || ''}
              labelClassName={styles.filterListItemCheckboxLabel}
              onChange={onCheckBoxChange}
              checked={Boolean(
                option?.name && filters?.size?.includes(option.name)
              )}
            />
          </li>
        ))}
      </ul>
    </FilterItem>
  );
};

export default SizeFilter;
