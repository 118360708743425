import {
  useQuery,
  QueryResult,
  QueryOptions,
  WatchQueryFetchPolicy,
  QueryFunctionOptions,
} from '@apollo/client';
// Api
import { GET_SPORTS } from 'api/sports/queries';
// Types
import { Sports, SportsVariables } from 'api/sports/types/Sports';
import { GetSportsInput } from 'api/graphql-global-types';

type BasicQueryOptions = Omit<
  QueryOptions,
  'query' | 'variables' | 'fetchPolicy'
> & {
  fetchPolicy?: WatchQueryFetchPolicy;
} & QueryFunctionOptions;

type UseGetAthleteMerchProductsOptions = {
  options?: BasicQueryOptions;
  variables?: GetSportsInput;
};

export const useGetSports = (
  options?: UseGetAthleteMerchProductsOptions
): QueryResult<Sports, SportsVariables> => {
  const sports = useQuery<Sports, SportsVariables>(GET_SPORTS, {
    variables: {
      input: {
        ...options?.variables,
      },
    },
    ...options?.options,
  });

  return sports;
};
