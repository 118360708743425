import { ChangeEvent } from 'react';
// Hooks
import { useGetSports } from 'hooks';
// Types
import { GetStores_stores_filters_sports } from 'api/store/types/GetStores';
import { GetMemorabilia_getMemorabilia_filters_sports } from 'api/memorabilia/types/GetMemorabilia';
// Context
import { useFiltersContext } from 'components/common2/PageWithFiltersWrapper/PageWithFiltersContext';
// Ui2
import CheckBox from 'ui2/CheckBox/CheckBox';
// Components
import FilterItem from '../FilterItem/FilterItem';
// Styles
import styles from './AthleteSportFilter.module.scss';

type AthleteSportFilterProps = {
  availableOptions:
    | Omit<GetStores_stores_filters_sports, '__typename'>[]
    | Omit<GetMemorabilia_getMemorabilia_filters_sports, '__typename'>[];
};

export const AthleteSportFilter = ({
  availableOptions,
}: AthleteSportFilterProps) => {
  const { filters, setFilters } = useFiltersContext();
  const { data } = useGetSports();

  const allSports = data?.sports || [];

  // show only selected sport
  const sportsOptions = filters?.sports?.length
    ? availableOptions?.filter((item) =>
        filters?.sports?.includes(Number(item.id))
      )
    : availableOptions;

  const onCheckBoxChange = ({
    target: { id },
  }: ChangeEvent<HTMLInputElement>) => {
    const sports: number[] = filters?.sports?.includes(Number(id))
      ? filters?.sports?.filter((i) => i !== Number(id)) || []
      : [...(filters?.sports || []), Number(id)];

    // remove tags that are not related to selected sport
    const selectedHashtagsIds = filters.hashtagIds;
    const selectedSportsWithHashtags = allSports.filter((item) =>
      sports.includes(item.id)
    );
    const hashtagIdsOfSelectedSports = selectedHashtagsIds.filter(
      (selectedItem) =>
        selectedSportsWithHashtags.some((sportItem) =>
          sportItem.hashtags?.find((tag) => tag.id === selectedItem)
        )
    );

    setFilters({
      ...filters,
      sports,
      hashtagIds: hashtagIdsOfSelectedSports,
    });
  };

  const sortedSportsOptions = sportsOptions.slice().sort((a, b) => {
    const nameA = a?.name?.toLowerCase() || '';
    const nameB = b?.name?.toLowerCase() || '';

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  return (
    <FilterItem title="Sport">
      <ul>
        {sortedSportsOptions?.map((option, index) => (
          <li key={`${index}_${option.id}`}>
            <CheckBox
              className={styles.filterListItemCheckbox}
              name={String(option.id)}
              label={option?.name || 'Empty sport name'}
              labelClassName={styles.filterListItemCheckboxLabel}
              onChange={onCheckBoxChange}
              checked={Boolean(
                option?.id && filters?.sports?.includes(option.id)
              )}
            />
          </li>
        ))}
      </ul>
    </FilterItem>
  );
};

export default AthleteSportFilter;
