import { ChangeEvent, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroll-component';
// Context
import { useFiltersContext } from 'components/common2/PageWithFiltersWrapper/PageWithFiltersContext';
// Api
import { GET_STORES_NAMES_LIST } from 'api/store/queries';
// Types
import {
  UserRole,
  StoresOrderBy,
  SortDirection,
} from 'api/graphql-global-types';
import {
  GetStoresNamesList,
  GetStoresNamesListVariables,
  GetStoresNamesList_stores_entities,
} from 'api/store/types/GetStoresNamesList';
// Ui
import InfiniteScrollLoader from 'ui/InfiniteScrollLoader/InfiniteScrollLoader';
// Ui2
import CheckBox from 'ui2/CheckBox/CheckBox';
// Components
import FilterItem from '../FilterItem/FilterItem';
// Styles
import styles from './ContentCreatorsFilter.module.scss';

const PER_PAGE = 8;

const ContentCreatorsFilter = () => {
  const {
    filters,
    storesListFilter,
    setFilters,
    searchKey,
    search,
  } = useFiltersContext();

  const inputVariables = useMemo(
    () => ({
      orderBy: StoresOrderBy.storeName,
      direction: SortDirection.ASC,
      limit: PER_PAGE,
      offset: 0,
      ...(storesListFilter && {
        [storesListFilter]: true,
      }),
    }),
    [storesListFilter]
  );

  const { data, fetchMore } = useQuery<
    GetStoresNamesList,
    GetStoresNamesListVariables
  >(GET_STORES_NAMES_LIST, {
    variables: {
      storeRoles: [UserRole.ContentCreator],
      input: {
        ...inputVariables,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const onCheckBoxChange = ({
    target: { id },
  }: ChangeEvent<HTMLInputElement>) => {
    const contentCreatorIds: string[] = filters?.contentCreatorIds?.includes(id)
      ? filters?.contentCreatorIds?.filter((creatorId) => creatorId !== id) ||
        []
      : [...(filters?.contentCreatorIds || []), id];

    setFilters({ ...filters, contentCreatorIds });
  };

  const fetchMoreData = () => {
    const prevOffset = data?.stores.offset || 0;
    const offset = prevOffset ? prevOffset + PER_PAGE : PER_PAGE;

    fetchMore({
      variables: {
        input: {
          ...inputVariables,
          offset,
        },
      },
    });
  };

  const creators = data?.stores.entities || [];
  const total = data?.stores.total;
  const hasMore = data ? creators.length !== total : false;
  const infiniteScrollHeight = !creators?.length
    ? 48
    : creators.length >= 6
    ? 240
    : creators.length * 48;

  const getIfChecked = (
    creator: GetStoresNamesList_stores_entities
  ): boolean => {
    let isChecked = Boolean(filters?.contentCreatorIds?.includes(creator?.id));

    if (
      searchKey === 'storeName' &&
      creator?.storeDetails?.storeName === search?.['storeName']
    ) {
      isChecked = true;
    }

    return isChecked;
  };

  return (
    <>
      {!!creators.length && (
        <FilterItem title="Content creators">
          <InfiniteScroll
            className={styles.contentCreatorFilterWrapper}
            dataLength={creators.length}
            next={fetchMoreData}
            hasMore={hasMore}
            height={infiniteScrollHeight}
            loader={<InfiniteScrollLoader size="small" />}
          >
            <ul>
              {creators.map((creator) => (
                <li key={creator.id}>
                  <CheckBox
                    className={styles.filterListItemCheckbox}
                    name={creator.id}
                    label={creator?.storeDetails?.storeName || ''}
                    labelClassName={styles.filterListItemCheckboxLabel}
                    onChange={onCheckBoxChange}
                    checked={getIfChecked(creator)}
                  />
                </li>
              ))}
            </ul>
          </InfiniteScroll>
        </FilterItem>
      )}
    </>
  );
};

export default ContentCreatorsFilter;
