// Types
import { ChangeEvent } from 'react';
import { MemorabiliaProductType } from 'api/graphql-global-types';
import { GetMemorabilia_getMemorabilia_filters_types } from 'api/memorabilia/types/GetMemorabilia';
// Context
import { useFiltersContext } from 'components/common2/PageWithFiltersWrapper/PageWithFiltersContext';
// Ui2
import CheckBox from 'ui2/CheckBox/CheckBox';
// Components
import FilterItem from '../FilterItem/FilterItem';
// Styles
import styles from './MemorabiliaProductTypeFilter.module.scss';

type ProductTypeFilterProps = {
  availableOptions: GetMemorabilia_getMemorabilia_filters_types[];
};

export const MemorabiliaProductTypeFilter = ({
  availableOptions,
}: ProductTypeFilterProps) => {
  const { filters, setFilters } = useFiltersContext();

  const onCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name: MemorabiliaProductType = e.target
      .name as MemorabiliaProductType;

    const productType: MemorabiliaProductType[] = filters?.productType?.includes(
      name
    )
      ? filters.productType.filter((i) => i !== name) || []
      : [...(filters?.productType || []), name];

    setFilters({ ...filters, productType });
  };

  const sortedProductTypeOptions = availableOptions.slice().sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  return (
    <FilterItem title="Product Type">
      <ul>
        {sortedProductTypeOptions.map(({ name }, index) => (
          <li key={`${name}_${index}`}>
            <CheckBox
              className={styles.root}
              name={name}
              label={name}
              labelClassName={styles.label}
              onChange={onCheckBoxChange}
              checked={filters.productType?.includes(name)}
            />
          </li>
        ))}
      </ul>
    </FilterItem>
  );
};

export default MemorabiliaProductTypeFilter;
