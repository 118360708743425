import { ChangeEvent, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroll-component';
// Context
import { useFiltersContext } from 'components/common2/PageWithFiltersWrapper/PageWithFiltersContext';
// Api
import { GET_STORES_NAMES_LIST } from 'api/store/queries';
// Types
import {
  UserRole,
  StoresOrderBy,
  SortDirection,
} from 'api/graphql-global-types';
import {
  GetStoresNamesList,
  GetStoresNamesListVariables,
  GetStoresNamesList_stores_entities,
} from 'api/store/types/GetStoresNamesList';
// Ui
import InfiniteScrollLoader from 'ui/InfiniteScrollLoader/InfiniteScrollLoader';
// Ui2
import CheckBox from 'ui2/CheckBox/CheckBox';
// Components
import FilterItem from '../FilterItem/FilterItem';
// Styles
import styles from './OrganizationsFilter.module.scss';

const PER_PAGE = 8;

const OrganizationsFilter = () => {
  const {
    filters,
    storesListFilter,
    setFilters,
    searchKey,
    search,
  } = useFiltersContext();

  const inputVariables = useMemo(
    () => ({
      orderBy: StoresOrderBy.storeName,
      direction: SortDirection.ASC,
      limit: PER_PAGE,
      offset: 0,
      ...(storesListFilter && {
        [storesListFilter]: true,
      }),
    }),
    [storesListFilter]
  );

  const { data, fetchMore } = useQuery<
    GetStoresNamesList,
    GetStoresNamesListVariables
  >(GET_STORES_NAMES_LIST, {
    variables: {
      storeRoles: [UserRole.Organization],
      input: {
        ...inputVariables,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const onCheckBoxChange = ({
    target: { id },
  }: ChangeEvent<HTMLInputElement>) => {
    const organizationIds: string[] = filters?.organizationIds?.includes(id)
      ? filters?.organizationIds?.filter((orgId) => orgId !== id) || []
      : [...(filters?.organizationIds || []), id];

    setFilters({ ...filters, organizationIds });
  };

  const fetchMoreData = () => {
    const prevOffset = data?.stores.offset || 0;
    const offset = prevOffset ? prevOffset + PER_PAGE : PER_PAGE;

    fetchMore({
      variables: {
        input: {
          ...inputVariables,
          offset,
        },
      },
    });
  };

  const orgs = data?.stores.entities || [];
  const total = data?.stores.total;
  const hasMore = data ? orgs.length !== total : false;
  const infiniteScrollHeight = !orgs?.length
    ? 48
    : orgs.length >= 6
    ? 240
    : orgs.length * 48;

  const getIfChecked = (org: GetStoresNamesList_stores_entities): boolean => {
    let isChecked = Boolean(filters?.organizationIds?.includes(org?.id));

    if (
      searchKey === 'storeName' &&
      org?.storeDetails?.storeName === search?.['storeName']
    ) {
      isChecked = true;
    }

    return isChecked;
  };

  return (
    <>
      {!!orgs.length && (
        <FilterItem title="Organizations">
          <InfiniteScroll
            className={styles.orgsFilterWrapper}
            dataLength={orgs.length}
            next={fetchMoreData}
            hasMore={hasMore}
            height={infiniteScrollHeight}
            loader={<InfiniteScrollLoader size="small" />}
          >
            <ul>
              {orgs.map((org) => (
                <li key={org.id}>
                  <CheckBox
                    className={styles.filterListItemCheckbox}
                    name={org.id}
                    label={org?.storeDetails?.storeName || ''}
                    labelClassName={styles.filterListItemCheckboxLabel}
                    onChange={onCheckBoxChange}
                    checked={getIfChecked(org)}
                  />
                </li>
              ))}
            </ul>
          </InfiniteScroll>
        </FilterItem>
      )}
    </>
  );
};

export default OrganizationsFilter;
