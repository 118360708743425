// Types
import { ChangeEvent } from 'react';
import { MemorabiliaSaleMethod } from 'api/graphql-global-types';

// Context
import { useFiltersContext } from 'components/common2/PageWithFiltersWrapper/PageWithFiltersContext';
// Ui2
import CheckBox from 'ui2/CheckBox/CheckBox';
// Components
import FilterItem from '../FilterItem/FilterItem';
// Styles
import styles from './SaleMethodFilter.module.scss';

type SaleMethodFilterProps = {
  availableOptions: MemorabiliaSaleMethod[];
};

export const SaleMethodFilter = ({
  availableOptions,
}: SaleMethodFilterProps) => {
  const { filters, setFilters } = useFiltersContext();

  const onCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name: MemorabiliaSaleMethod = e.target.name as MemorabiliaSaleMethod;

    const saleMethod: MemorabiliaSaleMethod[] = filters?.saleMethod?.includes(
      name
    )
      ? filters.saleMethod.filter((i) => i !== name) || []
      : [...(filters?.saleMethod || []), name];

    setFilters({ ...filters, saleMethod });
  };

  return (
    <FilterItem title="Sale Type">
      <ul>
        {availableOptions.map((name, index) => (
          <li key={`${name}_${index}`}>
            <CheckBox
              className={styles.root}
              name={name}
              label={name}
              labelClassName={styles.label}
              onChange={onCheckBoxChange}
              checked={filters.saleMethod?.includes(name)}
            />
          </li>
        ))}
      </ul>
    </FilterItem>
  );
};

export default SaleMethodFilter;
