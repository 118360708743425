// Types
import { ChangeEvent } from 'react';
import { MerchType } from 'api/graphql-global-types';
import { GetMerchProducts_getMerchProducts_filters_type } from 'api/merch/types/GetMerchProducts';
// Context
import { useFiltersContext } from 'components/common2/PageWithFiltersWrapper/PageWithFiltersContext';
// Ui2
import CheckBox from 'ui2/CheckBox/CheckBox';
// Components
import FilterItem from '../FilterItem/FilterItem';
// Styles
import styles from './MerchTypeFilter.module.scss';

type MerchTypeFilterProps = {
  availableOptions: GetMerchProducts_getMerchProducts_filters_type[];
};

export const MerchTypeFilter = ({ availableOptions }: MerchTypeFilterProps) => {
  const { filters, setFilters } = useFiltersContext();

  const onCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name: MerchType = e?.target?.name as MerchType;

    const merchType: MerchType[] = filters?.merchType?.includes(name)
      ? filters?.merchType?.filter((i) => i !== name) || []
      : [...(filters?.merchType || []), name];

    setFilters({ ...filters, merchType });
  };

  const sortedMerchTypeOptions = availableOptions.slice().sort((a, b) => {
    const nameA = a?.name?.toLowerCase() || '';
    const nameB = b?.name?.toLowerCase() || '';

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  const optionName = (typeName) => {
    switch (typeName) {
      case 'TShirt': {
        return 'T-Shirts';
      }
      case 'Hoodie': {
        return 'Hoodies';
      }
      case 'Hat': {
        return 'Hats';
      }
      case 'Joggers': {
        return 'Joggers';
      }
      case 'RashGuard': {
        return 'Compression Shirts';
      }
    }
  };

  return (
    <FilterItem title="Type">
      <ul>
        {sortedMerchTypeOptions?.map((option, index) => (
          <li key={`${index}_${option.name}`}>
            <CheckBox
              className={styles.filterListItemCheckbox}
              name={option.name || ''}
              label={optionName(option.name)}
              labelClassName={styles.filterListItemCheckboxLabel}
              onChange={onCheckBoxChange}
              checked={Boolean(
                option?.name && filters?.merchType?.includes(option.name)
              )}
            />
          </li>
        ))}
      </ul>
    </FilterItem>
  );
};

export default MerchTypeFilter;
